<template>
  <div class="intract-app-bar" v-if="appBarData.visible">
    <v-app-bar app :color="appBarData.color" dark>
      <v-app-bar-nav-icon
        class="non-focused"
        v-if="$route.path != '/'"
        @click="goBack()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="$route.meta.customAppBarTitle"
        class="font-weight-medium"
        style="font-size: 18px"
        >{{ appBarData.title }}</v-toolbar-title
      >
      <v-toolbar-title
        v-else
        class="font-weight-medium"
        style="font-size: 18px"
        >{{ $route.meta.title }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div v-if="$route.name == 'Home'">
        <v-row>
          <v-btn icon @click="refreshPage"><v-icon>mdi-refresh</v-icon></v-btn>
          <notification-icon v-if="!currentUser.is_driver" />
        </v-row>
      </div>
      <!-- <div v-if="$route.name == 'ReportCardDashboard'">
        <v-row>
          <v-btn icon @click="$router.push({name:'ReportCardSetup'})"><v-icon>mdi-cog</v-icon></v-btn>
        </v-row>
      </div> -->
      <v-btn
        v-if="appBarData.iconButton"
        icon
        class="non-focused"
        @click="appBarData.iconButton.action"
      >
        <v-icon size="20">{{ appBarData.iconButton.icon }}</v-icon>
      </v-btn>

      <v-menu v-if="appBarData.actions.length" right bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="action in appBarData.actions"
            :key="action.id"
            @click="action.onClick"
            :class="action.icon == 'mdi-delete' ? 'red--text' : ''"
          >
            <v-icon
              :color="action.icon == 'mdi-delete' ? 'red' : ''"
              class="pr-2"
              v-if="action.icon"
              >{{ action.icon }}</v-icon
            >
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-progress-linear
        indeterminate
        v-if="isLoading"
        color="primary"
        background-color="white"
        style="
           {
            z-index: 100;
          }
        "
        fixed
        bottom
      ></v-progress-linear>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import NotificationIcon from "@components/base/NotificationIcon";
export default {
  name: "IntractAppBar",
  components: {
    NotificationIcon,
  },
  data() {
    return {
      hasHistory: false,
      appBarTitle: null,
      notificationsCount: 3,
    };
  },
  computed: {
    ...mapGetters(["isLoading", "appBarData","currentUser"]),
  },
  methods: {
    ...mapActions(["showSnackbar", "resetAppBarOptions"]),
    refreshPage() {
      this.showSnackbar({ title: "Refreshing...", type: "info" });
      setTimeout(() => document.location.reload(), 500);
    },
    goBack() {
      // this.$router.go(-1);
      // if (window.history.state != null) {
      //   this.$router.back();
      // }
      // else this.$router.push({ name: "Home" });

      const vm = this;
      const originRoute = vm.$route;
      vm.$router.go(-1);
      // Of course, we must detect until a $nextTick is reached
      setTimeout(() => {
        // If the route is not changed, we do the redirect
        if (originRoute == vm.$route) {
          // Redirect to the home path by default
          vm.$router.replace("/");
        }
      }, 500);
    },
  },

  // watch: {
  //   $route: function () {
  //     this.appBarTitle = "";
  //     this.appBarTitle = this.$route.meta.customAppBarTitle? this.appBarData.title : this.$route.meta.title;

  //   },
  //   appBarData: {
  //     handler: function () {
  //       console.log(this.appBarData);
  //       // this.appBarTitle = this.appBarData.title ?? this.$route.meta.title;
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style scoped>
.v-app-bar {
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px !important;
}
</style>