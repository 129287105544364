<template>
  <div>
    <!-- splash -->

    <v-container class="px-1 py-2">
      <h2 class="mx-2 mb-1 text-md-center">{{ currentInstitution.name }}</h2>
      <v-row no-gutters class="mx-md-16 justify-md-center">
        <v-col
          v-for="feature in activeFeatures"
          :key="feature.id"
          cols="4"
          md="2"
        >
          <v-card
            @click="featureClicked(feature)"
            class="rounded text-center mx-1 py-4 my-1 my-md-2 non-focused"
          >

            <feature-svg v-if="feature.image" :name="feature.image" width="65px" height="65px" />
            <img v-else-if="feature.image_href" :src="getImageUrl(feature.image_href)" height="65px" width="65px" />
   
            <!-- <feature-svg :name="feature.image" width="65px" height="65px" /> -->
            <p class="feature-title mb-0 mt-2 font-weight-medium text-truncate">
              {{ feature.title }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-layout class="text-center mt-2" align-center justify-center>
        <img
          width="40px"
          class="d-inline mr-2"
          src="@assets/logo.png"
          alt="Intract Logo"
        />
        <div class="d-inline font-weight-medium">Powered by Intract</div>
      </v-layout>
    </v-container>

    <ProfileEditDialog 
      :visible="showProfileDetailsDialog"
      @close="showProfileDetailsDialog = false"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FeatureSvg from "@assets/images/FeatureSvg.vue";
// import Helper from "@utils/misc";
import Mixins from "@utils/mixins";
import { Browser } from "@capacitor/browser";
import ProfileEditDialog from "@components/dialogs/ProfileEditDialog";

import EventBus from "@utils/event_bus";
export default {
  name: "Home",
  components: {
    FeatureSvg,
    ProfileEditDialog
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  data() {
    return {
      showProfileDetailsDialog: false,
      platform: null,
      pullRefresh: null,
      // features: [
      //   { id: 1, title: "Messages", image: "messages", route: "Messages" },
      //   { id: 2, title: "Notice Board", image: "notice", route: "NoticeBoard" },
      //   { id: 22, title: "Books", image: "books", route: "Books" },
      //   { id: 17, title: "Fees", image: "books", route: "AllFeeStudents" },
      //   {
      //     id: 18,
      //     title: "Online Class",
      //     image: "live-lectures",
      //     route: "OnlineClasses",
      //   },
      //   // { id: 3, title: "Homework", image: "homework" },
      //   { id: 14, title: "Downloads", image: "downloads", route: "Downloads" },
      //   { id: 5, title: "Timetable", image: "timetable", route: "Timetable" },
      //   { id: 8, title: "Video Lectures", image: "messages", route: "AllVideoSubjects" },
      //   { id: 13, title: "Feedback", image: "downloads", route: "Feedback" },
      //   { id: 12, title: "Ask", image: "timetable", route: "Ask" },
      //   { id: 11, title: "Holidays", image: "downloads", route: "Holidays" },
      // ],

      // activeFeatures: [],
      PushNotifications: null,
      
      // visible: false,
      
    };
  },
  computed: {
    ...mapGetters(["currentUser","newNotificationsCount"]),

    features() {
      var self = this;
      return [
        { id: 1, title: "Messages", image: "messages", route: "Messages" },
        { id: 2, title: "Notice Board", image: "notice", route: "NoticeBoard" },
        { id: 3, title: "Homework", image: "homework", route: "Homework" },
        {
          id: 17,
          title: "Fees",
          image: "fees",
          action() {
            if (self.currentUser.is_student)
              self.$router.push({
                name: "StudentFeeDetails",
                params: { studentId: self.currentUser.id },
              });
            else self.$router.push({ name: "AllFeeStudents" });
          },
        },
        {
          id: 25,
          title: "Fees",
          image: "fees",
          action() {
            var url = self.endpoints.feesPortalLink + `/login/user/${self.currentInstitution.code}`;
            if (!url.includes("http")) url = "http://" + url;
            // window.open(url, "_newtab");
            Browser.open({ url: url });
          },
          hide: !this.currentUser.is_student,
        },
        { id: 22, title: "Books", image: "books", route: "Books" },
        {
          id: 8,
          title: "Video Lectures",
          image: "video_lectures",
          route: "AllVideoSubjects",
        },
        {
          id: 18,
          title: "Online Class",
          image: "live-lectures",
          route: "OnlineClasses",
        },
        {
          id: 19,
          title: "Tests",
          image: "tests",
          route: "AllOnlineTests",
        },
        {
          id: 20,
          title: "Attendance",
          image: "attendance",
          route: "Attendance",
        },
        { id: 5, title: "Timetable", image: "timetable", route: "Timetable" },
        {
          id: 6,
          title: "Gallery",
          image: "gallery",
          route: "Gallery",
        },
        { id: 7, title: "Bus", image_href: "bus-school.png", route: "BusLocation" },
        {
          id: 9,
          title: "Magazine",
          image: "magazine",
          route: "AllMagazinePosts",
        },
        { id: 10, title: "Exams", image: "exams", route: "AllExams" },
        { id: 4, title: "Events", image: "events", route: "AllEventsPosts" },
        { id: 11, title: "Holidays", image: "holidays", route: "Holidays" },
        { id: 12, title: "Ask", image: "ask", route: "Ask" },
        { id: 13, title: "Feedback", image: "feedback", route: "Feedback" },
        { id: 14, title: "Downloads", image: "downloads", route: "Downloads" },
        { id: 21, title: "Report Cards", image: "report-cards", route: "ReportCardDashboard" },
        { id: 23, title: "Circles", image: "circles", route: "AllCircleArticles" },
        { id: 24, title: "Planner", image_href: "Syllabus.png", route: "Planner" },
        { id: 26, title: "Activity Sheets", image: "activitySheets", route: "AllActivitySheetTopics" },
        {
          id: 15,
          title: "Website",
          image: "website",
          action() {
            var url = self.currentInstitution.website;
            if (!url.includes("http")) url = "http://" + url;
            // window.open(url, "_newtab");
            Browser.open({ url: url });
          },
          hide: !this.currentInstitution.website,
        },
      ];
    },

    activeFeatures() {
      if (this.currentUser.is_driver) return this.features.filter(f => f.id == 7)
      var webRestrictedFeatures = [14];
      var institutionFeatures = this.currentInstitution.features;
      if (
        institutionFeatures.includes("[") &&
        institutionFeatures.includes("]")
      ) {
        var parsedArray = JSON.parse(institutionFeatures); //parse
        // iterate over list and get matching values
        var validFeatures = this.features.filter(
          (i) => parsedArray.includes(i.id) && !i.hide
        );
        if (this.platform == "web") {
          validFeatures = validFeatures.filter(
            (i) => !webRestrictedFeatures.includes(i.id)
          );
        }
        return validFeatures;
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "showSnackbar",
      "setCurrentUser",
      "setCurrentInstitution",
      "setLoading",
      "setNewNotificationsCount",
    ]),

    

    async featureClicked(feature) {
      if (feature.route) this.$router.push({ name: feature.route });
      else feature.action();
    },

    getImageUrl(image) {
      return require('../assets/features/' + image);
    },

    async handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.visible = true;
      }
    },

    async initPush() {
      if (this.PushNotifications) this.PushNotifications.removeAllListeners();

      // code to avoid imports
      if (this.platform == "web") return;
      const getPushNotifications = () => import("@capacitor/core");
      this.PushNotifications = (
        await getPushNotifications()
      ).Plugins.PushNotifications;

      let permStatus = await this.PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await this.PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      // register
      this.PushNotifications.requestPermissions().then((permission) => {
        console.log(permission);
        if (permission.receive == "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          this.PushNotifications.register();
        } else {
          // No permission for push granted
          this.showSnackbar({
            title:
              "Notification has been disabled. To enable again, kindly restart the app",
            type: "error",
          });
        }
      });

      this.PushNotifications.addListener("registration", async (token) => {
        console.log(token);
        var response = await this.api.call(
          this.essentials,
          this.endpoints.saveFCMToken,
          this.api.Methods.POST,
          { fcm_token: token.value }
        );
        if (response) console.log("fcm saved");
      });

      this.PushNotifications.addListener("registrationError", (error) => {
        console.log("Error: " + JSON.stringify(error));
      });

      this.PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification) => {
          EventBus.$emit(
            "notifications__new_notification_received",
            notification
          );
          // self.setNewNotificationsCount(this.newNotificationsCount + 1);
          console.log("Push received: ", notification);
        }
      );

      this.PushNotifications.addListener(
        "pushNotificationActionPerformed",
        async (notification) => {
          const data = notification.notification.data;
          console.log(data);
          if (data && data.navigator) {
            var route = JSON.parse(data.navigator);
            if (route) this.$router.push(route);
          }
          console.log("Action performed", notification);
        }
      );
    },
    async onRefresh() {
      this.showSnackbar({ title: "Refreshing...", type: "info" });
      setTimeout(() => window.location.reload(), 500);
    }
  },

  async created() {
    this.platform = await this.Helper.getDevicePlatform();
    // this.activeFeatures = await this.getActiveFeatures();
    this.initPush();
    if(this.currentInstitution.preferences.app_student_extra_data){
      if (
        this.currentUser.is_student && 
          (
            !this.currentUser.dob || 
            !this.currentUser.address || 
            !(
              this.currentUser.image && 
              !this.currentUser.image.includes('users/default/default.jpg')
            ) ||
            !(
              this.currentUser.parent_profile.mother_image && 
              !this.currentUser.parent_profile.mother_image.includes('users/default/default.jpg')
            ) ||
            !(
              this.currentUser.parent_profile.father_image && 
              !this.currentUser.parent_profile.father_image.includes('users/default/default.jpg')
            ) ||
            !this.currentUser.parent_profile.mother_name ||
            !this.currentUser.parent_profile.father_name ||
            // !this.currentUser.aadhar ||
            // !this.currentUser.blood_group ||
            !this.currentUser.fathers_phone
          )
        ){
        // this.visible = true;
        this.showProfileDetailsDialog = true;
      }
    }
    // this.updateCurrentUserDetails();
    // Helper.initPullRefresh(this.updateCurrentUserDetails);
    // this.checkForUpdates();
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    // Helper.destroyPullRefresh();
    if (this.PushNotifications) this.PushNotifications.removeAllListeners();
  },
};
</script>
<style scoped>
.feature-title {
  font-size: 15px;
}
</style>
