import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

function lazyLoad(viewPath) {
  return () => import(`@views/${viewPath}`);
}

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { title: 'Home', keepAlive: true } },

  // AUTH
  // Auth Urls
  { path: '/login/institute', name: 'LoginInstitute', component: lazyLoad('auth/LoginInstitute.vue'), meta: { title: 'Login Institute' } },
  { path: '/login/user/:code', name: 'LoginUser', component: lazyLoad('auth/LoginUser.vue'), meta: { title: 'Login User' } },
  { path: '/register/user', name: 'RegisterUser', component: lazyLoad('auth/RegisterUser.vue'), meta: { title: 'Register User' } },
  { path: '/logout', name: 'Logout', component: lazyLoad('auth/Logout.vue'), meta: { title: 'Logout' } },
  { path: '/notifications', name: 'Notifications', component: lazyLoad('auth/Notifications.vue'), meta: { title: 'Notifications', keepAlive: true } },
  // { path: '/restricted', name: 'Restricted', component: lazyLoad('auth/Restricted.vue'), meta: { title: 'Restricted' } },
  // { path: '/password/reset', name: 'ResetPassword', component: lazyLoad('auth/ResetPassword.vue'), meta: { title: 'Reset Password' }, props:true },

  // Board
  { path: '/board/messages', name: 'Messages', component: lazyLoad('board/Messages.vue'), meta: { title: 'Messages', keepAlive: true } },
  { path: '/board/notice', name: 'NoticeBoard', component: lazyLoad('board/NoticeBoard.vue'), meta: { title: 'Notice Board', keepAlive: true } },
  { path: '/board/posts/:postId', name: 'ViewBoardPost', component: lazyLoad('board/ViewBoardPost.vue'), meta: { title: 'View Board Post', customAppBarTitle: true }, props: true },
  { path: '/board/posts/individual/:studentId', name: 'IndividualStudentMessages', component: lazyLoad('board/IndividualStudentMessages.vue'), meta: { title: 'Student Messages', keepAlive: true, customAppBarTitle: true }, props: true },
  
  // Profile
  { path: '/profile', name: 'Profile', component: lazyLoad('auth/Profile.vue'), meta: { title: 'Profile' } },

  // Admin
  { path: '/admin-panel', name: 'AdminPanel', component: lazyLoad('admin/AdminPanel.vue'), meta: { title: 'Admin Panel' } },
  { path: '/admin-panel/students', name: 'AdminStudentsList', component: lazyLoad('admin/students/AdminStudentsList.vue'), meta: { title: 'All Students', keepAlive: true } },
  { path: '/admin-panel/students/import-student', name: 'ImportStudent', component: lazyLoad('admin/students/ImportStudent.vue'), meta: { title: 'Import Students', keepAlive: true } },
  { path: '/admin-panel/students/:studentId', name: 'StudentDetails', component: lazyLoad('admin/students/StudentDetails.vue'), meta: { title: 'Student Details' } },
  { path: '/admin-panel/analytics', name: 'Analytics', component: lazyLoad('admin/Analytics.vue'), meta: { title: 'Analytics' } },
  { path: '/admin-panel/kraf-analytics', name: 'KrafAnalytics', component: lazyLoad('admin/KrafAnalytics.vue'), meta: { title: 'Analytics' } },
  { path: '/admin-panel/kraf-analytics/books/details', name: 'KrafBookAnalyticsDetails', component: lazyLoad('admin/BookAnalyticsDetails.vue'), meta: { title: 'Book Analytics' } },
  { path: '/admin-panel/kraf-analytics/activity_sheets/details', name: 'KrafActivitySheetsAnalyticsDetails', component: lazyLoad('admin/BookAnalyticsDetails.vue'), meta: { title: 'Activity Sheets Analytics' } },


  { path: '/admin-panel/staff', name: 'AdminStaffList', component: lazyLoad('admin/staff/AdminStaffList.vue'), meta: { title: 'All Staff', keepAlive: true } },
  { path: '/admin/staff/:staffId', name: 'StaffDetails', component: lazyLoad('admin/staff/StaffDetails.vue'), meta: { title: 'Staff Details' } },
  { path: '/admin-panel/classrooms/manage-extra-subjects', name: 'ManageExtraSubjects', component: lazyLoad('admin/classrooms/ManageExtraSubjects.vue'), meta: { title: 'Manage Extra Subjects' },  },
  { path: '/admin-panel/classrooms', name: 'AdminClassroomsList', component: lazyLoad('admin/classrooms/AdminClassroomsList.vue'), meta: { title: 'All Classrooms' } },
  { path: '/admin-panel/classrooms/:roomId', name: 'ClassroomDetails', component: lazyLoad('admin/classrooms/ClassroomDetails.vue'), meta: { title: 'Classroom Details' } },
  { path: '/admin-panel/classrooms/students/:roomId', name: 'ClassroomStudents', component: lazyLoad('admin/classrooms/ClassroomStudents.vue'), meta: { title: 'Classroom Students' } },


  // Downloads
  { path: '/downloads', name: 'Downloads', component: lazyLoad('misc/Downloads.vue'), meta: { title: 'All Downloads' } },
  
  // books
  { path: '/books', name: 'Books', component: lazyLoad('books/Books.vue'), meta: { title: 'Books', keepAlive: true } },
  { path: '/books/topics/:subjectId', name: 'AllBookTopics', component: lazyLoad('books/AllBookTopics.vue'), meta: { title: 'All Book Topics', keepAlive: true , customAppBarTitle: true}, props: true},
  { path: '/books/topics/items/:topicId', name: 'AllBookTopicItems', component: lazyLoad('books/AllBookTopicItems.vue'), meta: { title: 'All Book Topic Items', keepAlive: true, customAppBarTitle: true }, props: true },
  { path: '/books/topics/intros/:introId', name: 'BookTopicIntroduction', component: lazyLoad('books/TopicIntroduction.vue'), meta: { title: 'Topic Introduction', customAppBarTitle: true}, props: true },
  { path: '/books/topics/assignments/:assignmentId', name: 'BookTopicAssignment', component: lazyLoad('books/TopicAssignment.vue'), meta: { title: 'Topic Assignment', customAppBarTitle: true}, props: true },
  { path: '/books/topics/assignments/submissions/:submissionId', name: 'BookTopicAssignmentSubmission', component: lazyLoad('books/TopicAssignmentSubmission.vue'), meta: { title: 'Assignment Submission' }, },
  { path: '/books/qrscanner', name: 'BooksQRScanner', component: lazyLoad('books/BooksQRScanner.vue'), meta: { title: 'QR Scanner' }, },

  // activity sheets
  // { path: '/activity-sheets', name: 'ActivitySheets', component: lazyLoad('books/Books.vue'), meta: { title: 'Activity Sheets', keepAlive: true, is_activity: true } },
  { path: '/activity-sheets/topics', name: 'AllActivitySheetTopics', component: lazyLoad('books/AllBookTopics.vue'), meta: { title: 'All Activity Sheet Topics', keepAlive: true, customAppBarTitle:true, is_activity: true}, props: true},
  { path: '/activity-sheets/topics/items/:topicId', name: 'AllActivitySheetTopicItems', component: lazyLoad('books/AllBookTopicItems.vue'), meta: { title: 'All Activity Sheet Topic Items', keepAlive: true, customAppBarTitle: true, is_activity: true }, props: true },
  { path: '/activity-sheets/topics/intros/:introId', name: 'ActivitySheetTopicIntroduction', component: lazyLoad('books/TopicIntroduction.vue'), meta: { title: 'Topic Introduction', customAppBarTitle: true, is_activity: true}, props: true },
  { path: '/activity-sheets/topics/assignments/:assignmentId', name: 'ActivitySheetTopicAssignment', component: lazyLoad('books/TopicAssignment.vue'), meta: { title: 'Topic Assignment', customAppBarTitle: true, is_activity: true}, props: true },
  { path: '/activity-sheets/topics/assignments/submissions/:submissionId', name: 'ActivitySheetTopicAssignmentSubmission', component: lazyLoad('books/TopicAssignmentSubmission.vue'), meta: { title: 'Assignment Submission', is_activity: true }, },
  { path: '/activity-sheets/qrscanner', name: 'ActivitySheetsQRScanner', component: lazyLoad('books/BooksQRScanner.vue'), meta: { title: 'QR Scanner', is_activity: true }, },
  
  // homework
  { path: '/homework', name: 'Homework', component: lazyLoad('homework/Homework.vue'), meta: { title: 'Homework', keepAlive: true } },
  { path: '/homework/:homeworkId', name: 'HomeworkDetails', component: lazyLoad('homework/HomeworkDetails.vue'), meta: { title: 'Homework Details' } },
  { path: '/homework/submissions/:submissionId', name: 'HomeworkSubmission', component: lazyLoad('homework/HomeworkSubmission.vue'), meta: { title: 'Homework Submission' }, },


  // videos
  { path: '/videos/online-classes', name: 'OnlineClasses', component: lazyLoad('videos/OnlineClasses.vue'), meta: { title: 'Online Classes' }, },
  
  // fees
  { path: '/fees/students', name: 'AllFeeStudents', component: lazyLoad('fees/AllFeeStudents.vue'), meta: { title: 'All Fee Student Accounts', keepAlive: true }, },
  { path: '/fees/students/:studentId', name: 'StudentFeeDetails', component: lazyLoad('fees/StudentFeeDetails.vue'), meta: { title: "Student's Fee Details" }, },
  { path: '/fees/transactions', name: 'AllTransactions', component: lazyLoad('fees/AllTransactions.vue'), meta: { title: "All Fee Transactions", keepAlive: true }, },
  { path: '/fees/payment-details', name: 'FeePreferences', component: lazyLoad('fees/FeePreferences.vue'), meta: { title: "Fee Payment Details", keepAlive: false }, },
  { path: '/fees/payment-details/edit', name: 'EditFeePreferences', component: lazyLoad('fees/EditFeePreferences.vue'), meta: { title: "Edit Fee Payment Details", keepAlive: false }, },


  // Timetable 
  { path: '/timetable', name: 'Timetable', component: lazyLoad('timetable/Timetable.vue'), meta: { title: 'Timetable', keepAlive: true }},
  { path: '/timetable/day/:roomId/:dayNo', name: 'ViewTimetable', component: lazyLoad('timetable/ViewTimetable.vue'), meta: { title: 'Monday', customAppBarTitle: true }},

  //Report Card
  { path: '/report-cards', name: 'ReportCardDashboard', component: lazyLoad('report_cards/ReportCards.vue'), meta: { title: 'Report Card' }},
  { path: '/report-cards/setup', name: 'ReportCardSetup', component: lazyLoad('report_cards/ReportCardSetup.vue'), meta: { title: 'Groups & Parameters' }},
  { path: '/report-cards/generate', name: 'GenerateReportCards', component: lazyLoad('report_cards/GenerateReportCards.vue'), meta: { title: 'Generate Report Cards' }, props: true,},
  { path: '/report-cards/:group_id', name: 'ViewReportCards', component: lazyLoad('report_cards/GenerateReportCards.vue'), meta: { title: 'View Report Cards'}, props: true,},

  { path: '/report-cards/view/:report_id', name: 'ViewReportCard', component: lazyLoad('report_cards/ViewReportCard.vue'), meta: { title: 'Report Card' }},


  // Video Lectures 
  { path: '/videos', name: 'AllVideoSubjects', component: lazyLoad('videos/AllVideoSubjects.vue'), meta: { title: 'Video Lectures', keepAlive: true }, props: true},
  { path: '/videos/:subjectId', name: 'AllVideoModules', component: lazyLoad('videos/AllVideoModules.vue'), meta: { title: 'All Video Modules', customAppBarTitle: true, keepAlive: true}, props: true },
  { path: '/videos/:subjectId/:moduleId', name: 'AllVideoLectures', component: lazyLoad('videos/AllVideoLectures.vue'), meta: { title: 'All Video Lectures', customAppBarTitle:true, keepAlive: true}, props: true},
  { path: '/videos/:subjectId/:moduleId/:lectureId', name: 'VideoLecture', component: lazyLoad('videos/VideoLecture.vue'), meta: { title: 'Video Lecture', customAppBarTitle: true}, props: true},

  // Feedback
  { path: '/feedback', name: 'Feedback', component: lazyLoad('feedback/Feedback.vue'), meta: { title: 'Feedback'}, props: true},
  
  // Ask
  { path: '/ask', name: 'Ask', component: lazyLoad('ask/Ask.vue'), meta: { title: 'Ask'}, props: true},
  { path: '/ask/question/:questionId', name: 'Question', component: lazyLoad('ask/Question.vue'), meta: { title: 'Question', customAppBarTitle: true}, props: true},
  

  // Holidays
  { path: '/holidays', name: 'Holidays', component: lazyLoad('holidays/Holidays.vue'), meta: { title: 'Holidays'}, props: true},
  
  // gallery
  { path: '/gallery', name: 'Gallery', component: lazyLoad('gallery/Gallery.vue'), meta: { title: 'Gallery', keepAlive: true }, },
  { path: '/gallery/folder/:folderId', name: 'GalleryFolder', component: lazyLoad('gallery/GalleryFolder.vue'), meta: { title: 'Gallery Folder', customAppBarTitle: true, keepAlive:true }, },
  { path: '/gallery/:postId', name: 'GalleryPost', component: lazyLoad('gallery/GalleryPost.vue'), meta: { title: 'Gallery Post' }, },

  // attendance
  { path: '/attendance', name: 'Attendance', component: lazyLoad('attendance/Attendance.vue'), meta: { title: 'Attendance', keepAlive: true }, },
  { path: '/attendance/record', name: 'TakeAttendance', component: lazyLoad('attendance/TakeAttendance.vue'), meta: { title: 'Take Attendance' }, props: true },
  { path: '/attendance/record/:attendanceId', name: 'EditAttendance', component: lazyLoad('attendance/TakeAttendance.vue'), meta: { title: 'Edit Attendance' }, props: true },
  { path: '/attendance/record/history/:roomId?', name: 'AttendanceHistory', component: lazyLoad('attendance/AttendanceHistory.vue'), meta: { title: 'Attendance History', keepAlive:true } },
  { path: '/attendance/classroom/:roomId/:subjectId/:startDate/:endDate', name: 'ClassroomAttendance', component: lazyLoad('attendance/ClassroomAttendance.vue'), meta: { title: 'Classroom Attendance', keepAlive:true }, props: true },
  { path: '/attendance/classroom/:roomId/:startDate/:endDate', name: 'ClassroomAttendanceDaily', component: lazyLoad('attendance/ClassroomAttendance.vue'), meta: { title: 'Classroom Attendance - Daily', keepAlive:true }, props: true },
  { path: '/attendance/student/:subjectId/:studentId/:startDate/:endDate', name: 'StudentAttendance', component: lazyLoad('attendance/StudentAttendance.vue'), meta: { title: 'Student Attendance' } },
  { path: '/attendance/student/:roomId/:studentId/:startDate/:endDate', name: 'StudentAttendanceDaily', component: lazyLoad('attendance/StudentAttendance.vue'), meta: { title: 'Student Attendance - Daily' }, props: true },
  { path: '/attendance/qrscanner', name: 'AttendanceQRScanner', component: lazyLoad('attendance/AttendanceQRScanner.vue'), meta: { title: 'Attendance QR Scanner' }, },


  // magazine
  { path: '/magazine', name: 'AllMagazinePosts', component:lazyLoad('magazine/AllMagazinePosts.vue'), meta: { title: 'Magazine', keepAlive: true } },
  { path: '/magazine/:postId', name: 'ViewMagazinePost', component:lazyLoad('magazine/ViewMagazinePost.vue'), meta: { title: 'View Magazine Post', customAppBarTitle: true } },

  // events
  { path: '/events', name: 'AllEventsPosts', component:lazyLoad('events/AllEventsPosts.vue'), meta: { title: 'Events', keepAlive: true } },
  { path: '/events/:postId', name: 'ViewEventPost', component:lazyLoad('events/ViewEventPost.vue'), meta: { title: 'Event Details' } },

  // exams
  { path: '/exams', name: 'AllExams', component: lazyLoad('exams/AllExams.vue'), meta: { title: 'Exams' }, },

  // online tests
  { path: '/tests', name: 'AllOnlineTests', component: lazyLoad('tests/AllOnlineTests.vue'), meta: { title: 'Online Tests', keepAlive: true }, },
  { path: '/tests/details/:testId', name: 'TestDetails', component: lazyLoad('tests/TestDetails.vue'), meta: { title: 'Test Details' }, },
  { path: '/tests/results/:roomId?', name: 'TestResults', component: lazyLoad('tests/TestResults.vue'), meta: { title: 'Test Results' }, },

  // circles
  { path: '/circles', name: 'AllCircleArticles', component: lazyLoad('circles/AllCircleArticles.vue'), meta: { title: 'Circles' }, },
  { path: '/circles/article/:articleId', name: 'ViewCircleArticle', component: lazyLoad('circles/ViewCircleArticle.vue'), meta: { title: 'View Circle Article' }, },

  // redirect-device
  { path: '/redirect-device', name: 'RedirectDevice', component: lazyLoad('RedirectDevice.vue'), meta: { title: 'Redirect Device' }, },
  
  // planner
  { path: '/planner', name: 'Planner', component: lazyLoad('planner/Planner.vue'), meta: { title: 'Planner' }, }, 
  { path: '/planner/activity-types', name: 'ActivityType', component: lazyLoad('planner/ActivityType.vue'), meta: { title: 'Activity Types' }, },
    
  // BusLocation
  {path: '/buslocation', name: 'BusLocation', component: lazyLoad('bus/BusLocation.vue'), meta: {title: 'Bus Location'}},
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      setTimeout(() => {
        // document.querySelector('html').scrollTop = savedPosition.y;
        window.scroll(0, savedPosition.y);
      }, 100);
    }else {
      setTimeout(() => {
        // document.querySelector('html').scrollTop = savedPosition.y;
        window.scroll(0, 0);
        // alert("hmm")

      }, 100);
    }
  },
  mode: 'history'
})



router.beforeEach((to, from, next) => {
  const publicPages = ['/login/institute', '/login/user/*', '/register/user', '/password/reset', '/restricted', '/assets*','/circles/article/*','/redirect-device'];
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));


  // trying to access a restricted page + not logged in
  // redirect to login page
  if (!currentUser) {

    const authRequired = !publicPages.some(page => RegExp(page).test(to.path));
    if (authRequired) {
      next('/login/institute' + "?next=" + to.path);

    } else next();
  } else {

    // check privilege
    next();
    // else router.push(`/restricted?from=${to.path}`);

  }
});

export default router